<template>
  <div
    :disabled="disabled"
    :class="[
      'relative w-full h-full group/cell',
      {
        'cursor-not-allowed': disabled,
      },
    ]"
    @click="onEditMode"
  >
    <div
      :class="[
        'max-w-full leading-4',
        preview ? 'px-1.5' : 'p-2',
        contentClass,
        containerClass,
        {
          '!cursor-not-allowed': disabled,
        }
      ]"
    >
      {{ internalValue }}
      <span v-if="!internalValue" class="group-hover/cell:visible invisible">-</span>
    </div>
    <span
      v-if="isEditing && !preview"
      ref="editableSpan"
      :class="[
        'textarea',
        'outline-none !bg-white absolute top-0',
        'border-none ring-1 ring-primary-500',
        'leading-4 max-w-64 min-w-full min-h-full p-2',
        'z-10 max-h-64 overflow-y-auto',
        contentClass,
      ]"
      role="textbox"
      :contenteditable="!disabled"
      data-placeholder="-"
      @blur="onChange"
      @keydown.esc.stop="onChange"
      @keydown.enter.stop="($event.target as HTMLTextAreaElement)?.blur()"
      @input="updateInternalValue"
      @focus="onFocus"
    >
      {{ internalValue }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue'

const props = defineProps<{
  value: string | undefined
  preview?: boolean
  fieldId?: string
  containerClass?: string
  contentClass?: string
  disabled?: boolean
  regex?: RegExp
}>()

const emit = defineEmits<{
  (e: 'change', value: string, fieldId?: string): void
  (e: 'set-callback', callback: { open: () => void }): void
}>()

const internalValue = ref(props.value)
const isEditing = ref(false)
const editableSpan = ref<HTMLSpanElement | null>(null)

const updateInternalValue = (event: Event) => {
  const target = event.target as HTMLSpanElement
  const value = target.innerText
  if (!value.trim()) {
    target.innerText = ''
  }

  if (props.regex) {
    const match = value?.match(props.regex)
    const formattedValue = match ? match[0] : ''

    target.innerText = formattedValue
    onFocus()
  }

  internalValue.value = target.innerText
}

const onChange = () => {
  if (internalValue.value != props.value) {
    emit('change', internalValue.value || '', props.fieldId)
  }

  isEditing.value = false
}

const onEditMode = () => {
  if (props.preview || props.disabled) return

  isEditing.value = true
  nextTick(() => {
    if (editableSpan.value) {
      editableSpan.value.focus()
    }
  })
}

const onFocus = () => {
  if (editableSpan.value) {
    editableSpan.value.scrollTop = editableSpan.value?.scrollHeight
    const range = document.createRange()
    const selection = window.getSelection()
    selection?.removeAllRanges()
    range.selectNodeContents(editableSpan.value)
    range.collapse(false)
    selection?.addRange(range)
  }
}

defineExpose({
  focus: onEditMode,
  blur: () => {
    isEditing.value = false
  },
  changeValue: (value: string) => {
    internalValue.value = value
  },
})

onMounted(() => {
  internalValue.value = props.value
  emit('set-callback', {
    open: onEditMode,
  })
})

watch(
  () => props.value,
  (val) => {
    internalValue.value = val
  }
)
</script>

<style scoped lang="scss">
.textarea[contenteditable]:empty::before {
  content: attr(data-placeholder);
  color: theme('colors.gray.500');
}
</style>
